/**
 * Check https://mapstyle.withgoogle.com/
 */
export default [
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.attraction',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 30,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.government',
    stylers: [
      {
        saturation: -80,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
];
