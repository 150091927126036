import Vue from 'vue';

Vue.component('mamo-header', {
  data() {
    return {
      ariaHidden: null,
      overlayShown: false,
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      window.addEventListener('resize', this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
    window.removeEventListener('resize', this.getWindowHeight);
  },
  methods: {
    check(e) {
      e.preventDefault();
      this.overlayShown = !this.overlayShown;
      this.ariaHidden = this.overlayShown ? 'false' : 'true';
    },
    getWindowWidth(event) {
      // console.log('getWindowWidth 992px', document.documentElement.clientWidth);
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth < 992) {
        this.ariaHidden = this.overlayShown ? 'false' : 'true';
      } else {
        this.ariaHidden = null;
        this.overlayShown = false;
      }
    },

    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
    },
  },
});
