import Vue from 'vue';

import STYLES from './customMapStyles';
import CustomMarkerIcon from '../assets/images/pin.svg';
import MarkerClusterer from '@googlemaps/markerclustererplus';

const ClusterIcon = () =>
  window.btoa(`
<svg xmlns="http://www.w3.org/2000/svg" width="33.72" height="46.118" viewBox="0 0 33.72 46.118">
<path id="Vereinigungsmenge_2" data-name="Vereinigungsmenge 2" d="M7965.79-3249.329a84.015,84.015,0,0,1-7.833-9.411c-5.279-7.377-7.957-13.8-7.957-19.1A17.033,17.033,0,0,1,7966.859-3295a17.034,17.034,0,0,1,16.861,17.163c0,5.576-2.681,12.088-7.965,19.357a75.808,75.808,0,0,1-7.85,9.175,1.5,1.5,0,0,1-1.046.423A1.506,1.506,0,0,1,7965.79-3249.329Z" transform="translate(-7950 3295)" fill="#30b26c"/>
</svg>
`);

const OVERLAY_WIDTH = 348;
const OVERLAY_VISIBLE_FROM = 992;
const MAX_ZOOM = 19;
const MAX_INFOWINDOW_WIDTH = 400;

// see: https://stackoverflow.com/a/10666030/388026
const offsetCenter = (map, latlng, offsetx, offsety) => {
  // latlng is the apparent centre-point
  // offsetx is the distance you want that point to move to the right, in pixels
  // offsety is the distance you want that point to move upwards, in pixels
  // offset can be negative
  // offsetx and offsety are both optional

  const scale = Math.pow(2, map.getZoom());

  const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
  const pixelOffset = new google.maps.Point(offsetx / scale || 0, offsety / scale || 0);

  const worldCoordinateNewCenter = new google.maps.Point(
    worldCoordinateCenter.x - pixelOffset.x,
    worldCoordinateCenter.y + pixelOffset.y
  );

  const newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
  return newCenter;
};

//
//
window.checkAndAttachMapScript = function (callback) {
  if (window.google != null) {
    callback();
    return true;
  }

  window.mapApiInitialized = callback;
  let script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${window.MAMO.MAPS_API_KEY}&callback=mapApiInitialized`;
  document.body.appendChild(script);
};

//
//
const MamoPartnerEntry = Vue.component('mamo-partner-entry', {
  // name: 'MamoPartnerEntry',
  template: `<div class="partner__map-info">
    <p class="headline-s" v-html="item.location.name"></p>
    <p class="subtitle" v-html="item.location.subtitle"></p>
    <div class="partner__map-info__copy">
      <p v-html="printAddress()"></p>
      <p v-if="item.location.phone">Tel. {{item.location.phone}}</p>
      <p v-if="item.location.www" v-html="printLink()"></p>
      <p class="partner__map-info__copy-voucher" v-if="item.location.voucher"><b>{{item.location.voucher}}</b></p>
      <p v-if="item.location.voucherdetail">{{item.location.voucherdetail}}</p>
    </div>
    <div class="partner__map-info__options">
      <a v-if="item.location.detail" :href="item.location.detail" class="btn btn-link btn-arrow">Mehr</a>
      <button class="btn btn-link btn-arrow" v-if="showmapbutton" @click="onClickSearchResultItem(item.index)">Karte</button>
    </div>
  </div>`,
  props: ['item', 'showmapbutton'],
  methods: {
    //
    //
    onClickSearchResultItem(markerIndex) {
      console.log('onClickSearchResultItem()', markerIndex);
      this.$emit('selectmarker', markerIndex);
    },
    //
    //
    printAddress() {
      let output = '';
      try {
        const { zip, city, street } = this.item.location.address;
        if (zip != null) {
          output += `${zip} `;
        }
        if (zip != null) {
          output += `${city}`;
          if (street != null) {
            output += `<br/>`;
          }
        }
        if (street != null) {
          output += `${street}`;
        }
      } catch (err) {
        console.error(err);
      }
      return output;
    },
    //
    //
    printLink() {
      let output = '';
      let link = this.item.location.www;
      if (link != null) {
        const httpRegEx = /^(https?:\/\/)/g;
        const hasHttp = link.match(httpRegEx);
        let href = link;
        if (!hasHttp) {
          href = 'http://' + link;
        } else {
          link = link.replace(hasHttp[0], '');
        }
        output = `<a href="${href}" target="_blank">${link}</a>`;
      }
      return output;
    },
  },
});

//
//
Vue.component('mamo-partner', {
  data() {
    return {
      map: null,
      clusterer: null,
      infowindow: null,
      markers: null,
      windowWidth: 0,
      windowHeight: 0,
      isClusterOptionWindow: false,
      test: '',
      plz: '',
      searching: false,
      searchResults: [],
      categories: [],
      subcategories: {},
      selectedCategory: '',
      selectedSubcategory: '',
    };
  },
  mounted() {
    console.log('partnersuche mounted');
    window.checkAndAttachMapScript(this.initLocationSearch);
    window.onClickMarkerOption = this.onClickMarkerOption;
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    console.log('partnersuche beforeDestroy');
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    //
    //
    async initLocationSearch() {
      console.log('partnersuche initLocationSearch()', window.MAMO);
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: window.MAMO.PARTNER_MAP_CENTER,
        zoom: window.MAMO.PARTNER_MAP_ZOOM,
        maxZoom: MAX_ZOOM,
        styles: STYLES,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });
      await this.loadInitialPartner();
      await this.loadPartnerCategories();
    },
    //
    //
    async loadInitialPartner() {
      console.log('partner loadInitialPartner()');
      try {
        const response = await fetch(window.MAMO.PARTNER_DATA_URL, {
          method: 'GET',
        });
        if (response.ok) {
          const locations = await response.json();
          this.markers = [];
          locations.forEach((location, i) => {
            const marker = new google.maps.Marker({
              position: location.pos,
              icon: CustomMarkerIcon,
            });
            marker.addListener('click', () => {
              this.onMarkerClicked(i);
            });
            location.mamoIndex = i;
            this.markers.push({ location, marker });
          });

          this.clusterer = new MarkerClusterer(
            this.map,
            this.markers.map((data) => data.marker),
            {
              imagePath: '/images/cluster',
              zoomOnClick: false,
              ignoreHidden: true,
              styles: [
                {
                  url: `data:image/svg+xml;base64,${ClusterIcon()}`,
                  height: 46,
                  width: 34,
                  className: 'custom-clustericon',
                },
              ],
            }
          );

          // this.clusterer.setIgnoreHidden(true);

          google.maps.event.addListener(this.clusterer, 'clusterclick', this.onClusterClick);
          google.maps.event.addListener(this.map, 'zoom_changed', this.onZoomChanged);
        }
      } catch (err) {
        console.error('error trying to load initial partner', err);
      }
    },
    //
    //
    async loadPartnerCategories() {
      console.log('partner loadPartnerCategories()');
      try {
        const response = await fetch(window.MAMO.CATEGORIES_DATA_URL, {
          method: 'GET',
        });
        if (response.ok) {
          const categories = await response.json();
          categories.forEach((cat) => {
            if (!this.categories.includes(cat.title)) {
              this.categories.push(cat.title);
            }

            if (Array.isArray(cat.sub_categories)) {
              if (this.subcategories[cat.title] == null) {
                this.subcategories[cat.title] = [];
              }
              cat.sub_categories.forEach((subcat) => {
                if (!this.subcategories[cat.title].includes(subcat.title)) {
                  this.subcategories[cat.title].push(subcat.title);
                }
              });
              this.subcategories[cat.title].sort((a, b) => a.localeCompare(b));
            }
          });
          this.categories.sort((a, b) => a.localeCompare(b));
        }
      } catch (err) {
        console.error('error trying to load initial categories', err);
      }
    },
    //
    //
    onMarkerClicked(markerIndex, opts = {}) {
      console.log('partner onMarkerClicked()', markerIndex, opts);

      const currentMarker = this.markers[markerIndex];
      const X = Vue.extend(MamoPartnerEntry);
      const content = new X({
        propsData: {
          item: {
            index: currentMarker.location.mamoIndex,
            location: currentMarker.location,
          },
          showmapbutton: false,
        },
      });

      content.$mount();

      if (opts.panBefore === true) {
        this.map.panTo(currentMarker.marker.position);
        this.map.setZoom(MAX_ZOOM);
      }

      this.isClusterOptionWindow = false;
      this.openInfoWindow(content.$el, currentMarker.marker);
    },

    //
    //
    onClusterClick(cluster) {
      const currentZoom = this.map.getZoom();
      const markers = cluster.getMarkers();
      const center = cluster.getCenter();

      if (currentZoom === MAX_ZOOM) {
        const locations = [];
        let currentMarker;
        markers.forEach((m, index) => {
          const result = this.markers.find((mm) => mm.marker === m);
          if (result != null) {
            if (currentMarker == null) {
              currentMarker = result;
            }
            locations.push(result.location);
          }
        });

        const content = `<div class="partner__map-info">
          <p class="headline-s">Mehrere Partner an der gleichen Stelle:</p>
          <div class="partner__map-info__copy">
            <ul>
              ${locations
                .map(
                  (loc) =>
                    `<li><button class="btn btn-link" onclick="window.onClickMarkerOption(${loc.mamoIndex})">${loc.name}</button></li>`
                )
                .join('')}
            </ul>
          </div>
        </div>`;

        this.isClusterOptionWindow = true;
        this.openInfoWindow(content, center);
      } else {
        console.log('fitBounds ...');
        if (this.infowindow != null) {
          this.infowindow.close();
        }

        const bounds = new google.maps.LatLngBounds();

        markers.forEach((m) => {
          bounds.extend(m.position);
        });

        if (this.windowWidth > OVERLAY_VISIBLE_FROM) {
          this.map.fitBounds(bounds, { left: OVERLAY_WIDTH });
        } else {
          this.map.fitBounds(bounds);
        }
      }
    },

    //
    //
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
    },

    //
    //
    onClickMarkerOption(markerIndex) {
      console.log('onClickMarkerOption()', markerIndex);
      this.onMarkerClicked(markerIndex);
    },

    //
    //
    openInfoWindow(content, position) {
      console.log('openInfoWindow()');
      if (this.infowindow == null) {
        this.infowindow = new google.maps.InfoWindow();
      } else {
        this.infowindow.close();
      }

      this.infowindow.setContent(content);

      let maxWidth = 0;
      if (this.windowWidth > OVERLAY_VISIBLE_FROM) {
        maxWidth = this.windowWidth - OVERLAY_WIDTH - 100;
      }
      maxWidth = Math.min(MAX_INFOWINDOW_WIDTH, maxWidth);
      this.infowindow.setOptions({ maxWidth });

      let center;
      if (position.lat != null) {
        center = position;
        const offCenter = offsetCenter(this.map, position, 0, -30);
        this.infowindow.setPosition(offCenter);
        this.infowindow.open(this.map);
      } else {
        center = position.position;
        this.infowindow.open(this.map, position);
      }

      if (this.windowWidth > OVERLAY_VISIBLE_FROM) {
        const offsetX = Math.round(
          (this.windowWidth - OVERLAY_WIDTH) * 0.5 - (this.windowWidth * 0.5 - OVERLAY_WIDTH)
        );
        const newCenter = offsetCenter(this.map, center, offsetX, -40);
        this.map.panTo(newCenter);
      }
    },

    //
    //
    onZoomChanged() {
      const zoomLevel = this.map.getZoom();
      console.log('onZoomChanged()', zoomLevel);
      if (zoomLevel < MAX_ZOOM) {
        if (this.infowindow != null && this.isClusterOptionWindow === true) {
          this.isClusterOptionWindow = false;
          this.infowindow.close();
        }
      }
    },

    //
    //
    onSearchInput() {
      this.updateSearchResults();
    },

    //
    //
    onDisplaySearchResultItem(markerIndex) {
      console.log('onDisplaySearchResultItem()', markerIndex);
      this.onMarkerClicked(markerIndex, { panBefore: true });
    },

    //
    //
    onCategoryChanges() {
      this.selectedSubcategory = '';
      this.updateSearchResults();
    },

    //
    //
    updateSearchResults() {
      console.log(
        'updateSearchResults()',
        this.test,
        this.selectedCategory,
        this.selectedSubcategory,
        this.plz
      );
      const couldBeValidPLZ = /[0-9]{1,5}/.test(this.plz);
      if (this.test.length > 2 || this.selectedCategory !== '' || couldBeValidPLZ) {
        this.searching = true;
        if (this.infowindow != null) {
          this.infowindow.close();
        }
        this.searchResults = this.markers
          .filter((m) => {
            let nameMatch = true;
            if (this.test !== '') {
              nameMatch = m.location.name.toLowerCase().indexOf(this.test.toLowerCase()) !== -1;
            }

            let categoryMatch = true;
            if (this.selectedCategory !== '') {
              // const availableSubcategories = this.subcategories[this.selectedCategory];
              // console.log('availableSubcategories', availableSubcategories);
              // console.log('selectedCategory', this.selectedCategory);
              const doesMatchMainCategory = m.location.category === this.selectedCategory;
              categoryMatch = categoryMatch && doesMatchMainCategory;

              if (categoryMatch && this.selectedSubcategory !== '') {
                const doesMatchSubCategory = m.location.subcategory === this.selectedSubcategory;
                categoryMatch = categoryMatch && doesMatchSubCategory;
              }

              // const doesMatchSubCategory =
              //   Array.isArray(availableSubcategories) &&
              //   availableSubcategories.includes(m.location.category);
              // categoryMatch = doesMatchMainCategory || doesMatchSubCategory;
              // if (categoryMatch) {
              //   console.log(m.location, doesMatchMainCategory, doesMatchSubCategory);
              // }
            }

            let plzMatch = this.plz === '';
            if (this.plz !== '' && couldBeValidPLZ) {
              plzMatch = m.location.address.zip.indexOf(this.plz) === 0;
            }

            const all = nameMatch && categoryMatch && plzMatch;
            m.marker.setVisible(all);
            return all;
          })
          .map((m) => {
            return {
              index: m.location.mamoIndex,
              location: m.location,
            };
          });
        // console.log(this.searchResults);
      } else {
        this.searchResults = this.markers.forEach((m) => m.marker.setVisible(true));
        this.searching = false;
      }

      this.clusterer.repaint();
    },

    //
    //
    resetForm() {
      this.test = '';
      this.plz = '';
      this.selectedCategory = '';
      this.selectedSubcategory = '';

      this.updateSearchResults();

      this.map.panTo(window.MAMO.PARTNER_MAP_CENTER);
      this.map.setZoom(window.MAMO.PARTNER_MAP_ZOOM);
    },
  },
});
