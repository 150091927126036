import Vue from 'vue';
import './MamoHeader';
import './MamoPartnersuche';

import '../scss/main.scss';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/carousel';

console.log('test vue');

new Vue({
  el: '#root',
  created() {
    console.log('vue created');
  },
});
